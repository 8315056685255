<template>
  <div>
    <Consultancy></Consultancy>
  </div>
</template>
<script>

import Consultancy from "@/components/public/Consultancy/index.vue"

export default {

  data() {
    return {};
  },

  components: {
    Consultancy,
  },

};
</script>